
import {defineComponent, computed, PropType} from 'vue';
import {useI18n} from 'vue-i18n';
import DataTable from '@/components/tables/DataTable.vue';
import DataTableHeader from '@/components/tables/DataTableHeader.vue';
import {Rules} from "@/models";

export default defineComponent({
	emits: ["row-clicked", "page-changed", "item-removed"],
	components: {
		DataTable,
		DataTableHeader
	},
	props: {
		list: {
			type: Object as PropType<Rules.RuleList>,
			default: null
		},
		loading: {
			type: Boolean,
			default: false
		}
	},
	setup(props, {emit}) {
		const { t } = useI18n();

		const headers = computed(() => [
			{ text: `${t("dictionary.left")} ${t("dictionary.typeTag")}`, value: 'name1' },
			{ text: `${t("dictionary.right")} ${t("dictionary.typeTag")}`, value: 'name2' },
			{ text: t("dictionary.linkName"), value: 'link_name' },
			{ text: t("dictionary.ifFriendly"), value: 'if_friendly' },
			{ classCell: 'pl-0', icon: {name: 'basket', title: `${t("button.remove")} ${t("dictionary.rule")}`, action: (item: Rules.Rule) => emit("item-removed", item)}, width: "1%" },
		])

		const items = computed(() => props.list.items);

		return { headers, items }
	}
})
