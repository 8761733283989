
import {defineComponent, ref, computed} from 'vue';
import {useI18n} from 'vue-i18n';
import {useStore} from 'vuex';
import {injectOrThrow} from "@/helpers/inject";
import {Rules, TypeResources, LegalPairs, TypeLinks} from "@/models";
import {useForm, useField} from 'vee-validate';
import {ucFirst} from '@/helpers/firstRegister';

export default defineComponent({
	emits: ["dialog-closed", "item-added"],
	props: {
		open: {
			type: Boolean,
			default: false
		}
	},
	setup(_, {emit}) {
		const { t } = useI18n();
		const store = useStore();

		const {handleSubmit, handleReset} = useForm();

		const typeResources = computed(() => store.state.dictionaries.typeResources as TypeResources.TypeResource[]);
		const legalPairs = computed(() => store.state.dictionaries.legalPairs.map((item: LegalPairs.LegalPair) => {
			const findTypeResource = (id: number) => typeResources.value.find((typeResource: TypeResources.TypeResource) => typeResource.id === id);

			return {
				...item,
				name: `${findTypeResource(item.id_type_res1)?.name} - ${findTypeResource(item.id_type_res2)?.name}`
			}
		}));
		const typeLinks = computed(() => store.state.dictionaries.typeLinks as TypeLinks.TypeLink[]);

		const { value: selectedLegalPair, errorMessage: legalPairError } = useField<LegalPairs.LegalPair>('selectedLegalPair', 'required');
		function selectLegalPair(selected: LegalPairs.LegalPair) {
			selectedLegalPair.value = selected ?? {};
		}

		const { value: selectedTypeLink, errorMessage: typeLinkError } = useField<TypeLinks.TypeLink>('selectedTypeLink', 'required');
		function selectTypeLink(selected: TypeLinks.TypeLink) {
			selectedTypeLink.value = selected ?? {};
		}

		const isLoading = ref(false);
		const service = injectOrThrow<Rules.RuleService>('Rules.RuleService');

		const add = handleSubmit(async () => {
			isLoading.value = true;

			try {
				await service.add({
					id_legal_pair: selectedLegalPair.value.id,
					id_type_link: selectedTypeLink.value.id
				});

				store.commit('notification/successMessage', {title: t("form.notify.success.added", { name: ucFirst(t("dictionary.rule")) })});
				emit("item-added");
				closeModal();
			} catch (e) {
				return;
			} finally {
				isLoading.value = false;
			}
		});

		const closeModal = () => {
			emit('dialog-closed');
			handleReset();
		}

		return {
			t,
			legalPairs, selectedLegalPair, legalPairError, selectLegalPair,
			typeLinks, selectedTypeLink, typeLinkError, selectTypeLink,
			isLoading, add, closeModal
		}
	}
})
