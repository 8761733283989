
import {defineComponent, ref, onMounted, watch} from 'vue';
import {useI18n} from 'vue-i18n';
import {useStore} from 'vuex';
import {List} from "@/components/tables/DataTable";
import DataTable from "./components/listing/DataTable.vue";
import {injectOrThrow} from "@/helpers/inject";
import {Rules, Searches} from "@/models";
import {useRouter, useRoute} from "vue-router";
import Add from "./Add.vue";
import Remove from "@/views/components/dialogs/Remove.vue";
import {setCookie} from '@/helpers/cookie';

export default defineComponent({
	components: {
		DataTable,
		Add,
		Remove
	},
	setup() {
		const { t } = useI18n();
		const route = useRoute();
		const router = useRouter();
		const { state, commit, dispatch } = useStore();

		const changePage = (pagination: List) => {
			load({
				pageNumber: pagination.current,
				pageSize: pagination.size
			})

			commit('globals/setPageSize', pagination.size);
			setCookie("pageSize", pagination.size);
		};

		const list = ref(route.name === 'searches'
			? new Searches.SearchList({ size: state.globals.pageSize })
			: new Rules.RuleList({ size: state.globals.pageSize })
		);
		const query = ref<Rules.RuleQueryParams>({ pageSize: state.globals.pageSize });
		const service = injectOrThrow<Rules.RuleService>('Rules.RuleService');
		const searchService = injectOrThrow<Searches.SearchService>('Searches.SearchService');

		const loading = ref(true);
		const load = async (params: Rules.RuleQueryParams) => {
			if(state.globals.enterpriseId) {
				loading.value = true;
				query.value = { ...query.value, ...params, ...route.query };
				list.value = route.name === 'searches'
					? await searchService.search(query.value)
					: await service.list(query.value, state.globals.enterpriseId)
				loading.value = false;
			} else {
				router.push({name: "change_enterprise"})
			}
		}

		onMounted(async () => {
			await dispatch('dictionaries/getLegalPairs');
			await dispatch('dictionaries/getTypeResources');
			await dispatch('dictionaries/getTypeLinks');
			await load(query.value)
		});

		watch(() => route.query, () => {
			if(Object.keys(route.query).length)
				load(query.value)
		});

		const isAddOpened = ref(false);

		const item = ref({} as Rules.Rule);

		const isEditOpened = ref(false);
		const edit = (value: Rules.Rule) => {
			item.value = value;
			isEditOpened.value = true;
		};

		const isRemoveOpened = ref(false);
		const remove = (value: Rules.Rule) => {
			item.value = value;
			isRemoveOpened.value = true;
		};

		return {
			t, service,
			loading, load, changePage,
			list, item,
			isAddOpened,
			isEditOpened, edit,
			isRemoveOpened, remove
		}
	}
})
